$lightest-blue: #d1eeff;
$dark-gray: #222222;
$gray: #999999;
$med-gray: #666666;
$light-gray: #f6f6f6;
$lightest-gray: #bcbcbc;
$light-green: #00883f;
$light-orange: #e07647;
$red: #a62635;
$brown: #8e6451;

$blue: #28458e;
$bage: #fff8e5;
