@import "../styles/variables";
@import "../styles/colors";

.hero {
  width: 100%;
  padding: $padding-base * 6 $padding-base * 2 $padding-base * 2;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $bage;
  background-image: url("https://assets.decovostatic.com/static-images/pepsico-shop-icons/b2b-images/hero-background.svg");
  background-position: bottom right -480px;
  background-repeat: no-repeat;
  background-size: auto 682px;

  @include not-mobile {
    background-position: bottom right;
    background-size: auto 100%;
  }

  @include tablet {
    padding: $padding-base * 4;
  }
  @include laptop {
    padding: $padding-base * 5;
    flex-direction: row;
  }
}

.subtitle {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: $blue;
}

.heroAction {
  max-width: 460px;
  text-align: center;

  @include laptop {
    max-width: 500px;
    text-align: left;
  }

  p {
    color: $dark-gray;
    margin-top: $margin-base * 2;
    margin-bottom: $margin-base * 3;
    font-size: 18px;
    line-height: 1.3;
    @include not-mobile {
      margin-bottom: $margin-base * 2;
    }
  }
}

.heroImageWrapper {
  margin: 0;
  margin-top: $margin-base;
  max-width: 300px;
  max-height: 300px;

  @include tablet {
    margin-top: $margin-base * 3;
    max-width: 400px;
    max-height: 400px;
  }
  @include laptop {
    margin-top: 0;
    margin-left: $margin-base * 5;
    max-width: 500px;
    max-height: 500px;
  }
}

.heroImage {
  display: block;
  width: 100%;
}

.noWrap {
  white-space: nowrap;
}

.trust {
  padding: $padding-base * 8 $padding-base * 2 $padding-base * 2;
  background-color: $bage;

  .wrapper {
    margin: auto;
    max-width: 1100px;
  }

  .details {
    @include tablet {
      margin-left: $margin-base * 4;
    }
  }

  .subtitle {
    margin-top: $margin-base * 2;
    margin-bottom: $margin-base * 6;

    @include laptop {
      margin-top: $margin-base * 1;
      margin-bottom: $margin-base * 4;
    }
  }

  .benefitsWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    @include laptop {
      justify-content: space-between;
    }
  }

  .benefit {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-basis: 335px;

    @include tablet {
      flex-basis: 324px;
      padding: $padding-base;
    }

    img {
      margin-bottom: $margin-base * 3;
      width: 275px;
      height: 275px;
      @include not-mobile {
        width: 100%;
        height: auto;
      }
    }

    h3 {
      font-size: 21px;
      color: $blue;
      font-weight: bold;
      line-height: 1.3;
      margin: 0;
      margin-bottom: $margin-base * 1;
      width: 100%;
    }

    p {
      font-size: 18px;
      font-weight: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: $dark-gray;
      margin: 0;
      width: 100%;
    }

    div {
      // for IE; see notes in B2B-2710
      @include tablet {
        width: 324px;
      }
      width: 335px;
    }
  }
}

.customerBenefits {
  padding: $padding-base * 8 $padding-base * 2 0;

  .details {
    max-width: 740px;
    margin: auto;
    text-align: center;

    .subtitle {
      margin-bottom: $margin-base * 11;

      @include laptop {
        margin-bottom: $margin-base * 8;
      }
    }
  }

  .customerBenefitsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .customerBenefit {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: $margin-base * 11;

    @include tablet {
      max-width: 648px;
    }

    @include laptop {
      flex-basis: initial;
      max-width: 835px;
      flex-direction: row;
      align-items: center;

      &:nth-child(even) {
        .imageWrapper {
          margin-left: 0;
          margin-right: $margin-base * 8;
          order: -10;
        }
      }
    }

    .imageWrapper {
      img {
        height: auto;
        width: 100%;
      }
      max-width: 400px;
      width: 100%;
      margin-top: $margin-base * 4;
      @include laptop {
        margin-top: 0;
        margin-left: $margin-base * 8;
      }
    }

    .customerBenefitHeading {
      margin-bottom: $margin-base * 1;
    }

    p {
      font-size: 18px;
      font-weight: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: $dark-gray;
      margin: 0;
      width: 100%;
    }
  }
}

.guarantee {
  display: flex;
  flex-direction: column;
  padding: 0 $padding-base * 2;
  align-items: center;
  justify-content: center;

  @include laptop {
    margin: auto;
    max-width: 1250px;
    background: $bage;
    padding: 0;
  }

  .text {
    background: $bage;
    max-width: 625px;
    padding: $padding-base * 6 $padding-base * 2;

    .title,
    .subtitle {
      // need to specify children width for IE
      width: 100%;
    }

    .subtitle {
      color: $dark-gray;
    }

    @include tablet {
      padding: $padding-base * 12 $padding-base * 8;
    }

    @include laptop {
      padding: $padding-base * 5 $padding-base * 8;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      flex-basis: 50%;
    }
  }

  .imageWrapper {
    max-width: 625px;
    @include laptop {
      background: white;
      flex-basis: 50%;
    }
  }

  .image {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  @include laptop {
    flex-direction: row;
  }
}

.cta {
  padding: $padding-base * 11 $padding-base * 2 $padding-base * 13;
  text-align: center;
  .subtitle {
    margin-top: $margin-base * 3;
    margin-bottom: $margin-base * 2;
    color: $dark-gray;
    @include not-mobile {
      margin-top: $margin-base * 2;
    }
  }
}
