.wrapper {
  padding: 40px 0;
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiperContainer {
  & > div {
    transition-timing-function: linear !important;
  }
}

.slide {
  width: 80px;
  margin-right: 30px;
  img {
    width: 80px;
    height: 80px;
    display: block;
  }
}
